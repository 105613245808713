.heroWrapper {
  background: var(--page-hero-background-color, #ddd);
  margin-bottom: 4rem;
  position: relative;

  @media (--viewport-m) {
    width: 100%;
    margin: 0;
  }

  & picture {
    aspect-ratio: 1;
    background: var(--page-hero-background-color, #ddd);
    border-radius: 0;
    height: 100%;
    overflow: hidden;

    @media (--viewport-s) {
      aspect-ratio: 16/9;
      border-radius: var(--default-picture-sourceset-border-radius);
    }

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.backLinkContainer {
  bottom: 0;
  display: flex;
  gap: var(--spacing-s, 2.4rem);
  left: var(--spacing-s, 2.4rem);
  position: absolute;
  transform: translateY(50%);
  width: calc(100% - var(--spacing-s, 2.4rem));
  z-index: 1;
}

.content {
  padding: 4rem 0;
}

.metaTags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin: 1.2rem 0 2.4rem;
}

.introContainer {
  margin: 2.4rem 0 -1.2rem;
  font-weight: var(--font-weight-bold);
}

.pill {
  background-color: var(--project-hero-pill-background-color, var(--color-rolling-stone-100));
  border-radius: var(--border-radius-full);
  color: var(--color-midnight-800);
  display: block;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-s);
  padding: 0.4rem 1.2rem;
  text-decoration: none;
  transition: color 200ms ease-out, background-color 200ms ease-out;

  &:hover,
  &:focus {
    color: var(--color-white);
    background-color: var(--color-midnight-800);
  }
}

.subMeta {
  color: var(--color-projecthero-submeta, var(--color-midnight-800));
  display: block;
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
}
