.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 4rem 2.4rem;
  padding: 2.4rem 0;

  @media (--viewport-m) {
    gap: 7rem var(--default-grid-gap, 2.4rem);
    padding: 3.2rem 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-l) {
    grid-template-columns: repeat(3, 1fr);
    gap: 7rem var(--default-grid-gap, 3.2rem);
  }

  &.col2 {
    @media (--viewport-l) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.equalGap {
    gap: 4rem 2.4rem;

    @media (--viewport-m) {
      gap: var(--default-grid-gap, 2.4rem);
    }
  
    @media (--viewport-l) {
      gap: var(--default-grid-gap, 3.2rem);
    }
  }
}

.item {
  position: relative;

  & > * {
    height: 100%;
  }
}

.siteLabelContainer {
  left: 2rem;
  pointer-events: none;
  position: absolute;
  top: 2rem;
  z-index: 2;
}