.container {
  column-gap: 2.6rem;
  display: grid;
  font-size: var(--font-size-l);
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  line-height: var(--line-height-m);
  margin: 0;
  padding: 0;
  row-gap: 1.2rem;
  overflow: hidden;
  
  @media (--viewport-s) {
    font-size: var(--font-size-l);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  & dt {
    font-weight: var(--font-weight-medium);
  }

  & dd {
    margin: 0;
    grid-column-end: span 2;

    @media (--viewport-s) {
      grid-column-end: span 1;
      
    }
  }
}
